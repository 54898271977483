@import '../../../../scss/theme-bootstrap';

// Required long specificity to override default.
.cr21-refresh .comparison-item.product-brief-v2 .product-brief__cta {
  .product-brief__inventory-status {
    width: 100%;
    .product-inventory-status__list {
      margin: 0 auto;
      max-width: unset;
      .product-inventory-status__temp-oos-text {
        min-width: 80%;
      }
    }
  }
}

// Keep all code specific to non cr21 inside this condition.
// easier to remove once we have all markets rolled out.
@if $cr21_mpp == false {
  .comparison-item.product-brief-v2 {
    .product-brief__price {
      height: 32px;
      .product-brief__ppu {
        @include breakpoint($bp--medium-up) {
          line-height: 1.5;
        }
      }
    }
    .product-brief__cta .product-add-to-bag,
    .product-brief__inventory-status .product-inventory-status__list {
      max-width: 100%;
      @include breakpoint($bp--medium-up) {
        max-width: 49%;
      }
    }
    .product-brief__cta .product-brief__inventory-status {
      clear: both;
      @include breakpoint($bp--medium-up) {
        clear: #{$ldirection};
      }
    }
    .product-inventory-status__temp-oos-text .notify-status {
      position: relative;
    }
    .product-inventory-status__temp-oos-short-text {
      display: none;
    }
  }
}

.comparison-item.product-brief-v2 {
  margin: 0 auto;
  width: auto;
  max-width: unset;
  margin: 0 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breakpoint($bp--medium-up) {
    padding: 0;
    margin: 0 10px;
  }
  .product-brief {
    &__container {
      .slick-slider & {
        padding: 0;
        @include breakpoint($bp--xlarge-up) {
          padding: 0 10px;
        }
      }
    }
    &__rating-total {
      margin-top: 1px;
      @include breakpoint($bp--medium-up) {
        margin-top: 0;
      }
    }
    &__rating-count {
      color: $color-text-grey;
      font-size: 13px;
    }
    &__badge {
      display: none;
    }
    &__price {
      .product-price {
        padding-inline: 7px;
      }
    }
    &__image {
      margin-top: 27px;
      @include breakpoint($bp--medium-up) {
        margin-top: 0;
      }
    }
    &__details {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      min-height: unset;
      width: 100%;
      padding: 0;
      @include breakpoint($bp--xlarge-up) {
        padding: 0 15px;
      }
    }
    &__name-link {
      @include breakpoint($bp--medium-up) {
        min-height: unset;
      }
    }
    &__shade {
      margin-bottom: 2px;
      text-transform: uppercase;
      @include breakpoint($bp--medium-up) {
        margin-inline-start: 15px;
      }
      .cr21-refresh & {
        @include breakpoint($bp--medium-up) {
          margin: 0 auto;
        }
      }
    }
    &__product-name {
      @include sub-title--bold;
      color: $color--black;
      margin: 0;
    }
    &__footer {
      margin-top: 0;
      padding-bottom: 10px;
    }
    &__cta {
      @include breakpoint($bp--medium-up) {
        padding-inline: 15px;
      }
      .product-vto-link {
        max-width: 100%;
        padding-inline-start: 0;
        @include breakpoint($bp--medium-up) {
          max-width: 49.5%;
          margin-inline-start: 3px;
        }
      }
    }
    &__add-to-bag {
      max-width: 100%;
      padding-inline-end: 0;
      .cr21-refresh & {
        @include breakpoint($bp--medium-up) {
          max-width: 49.5%;
          padding-inline-end: 3px;
        }
      }
      &.hidden ~ &__inventory-status {
        display: flex;
      }
    }
    &__shades {
      min-height: 30px;
      overflow: hidden;
      @include breakpoint($bp--medium-up) {
        padding-inline: 15px;
      }
      .cr21-refresh & {
        overflow: visible;
      }
      .product-brief-shades {
        &__shade-item {
          width: 20%;
          @include breakpoint($bp--medium-up) {
            width: 12.5%;
          }
        }
        .cr21-refresh & {
          max-width: 110px;
          @include breakpoint($bp--medium-up) {
            max-width: 210px;
          }
        }
        .cr21-refresh & {
          .slick-prev,
          .slick-next {
            height: 46px;
            width: 30px;
            margin-block-start: -18px;
            @include breakpoint($bp--medium-up) {
              height: 54px;
              width: 34px;
              margin-block-start: -11px;
            }
          }
          .slick-prev {
            margin-inline-start: -46px;
            #{$ldirection}: 0;
            @include breakpoint($bp--medium-up) {
              margin-inline-start: -51px;
            }
          }
          .slick-next {
            margin-inline-end: -42px;
            @include breakpoint($bp--medium-up) {
              margin-inline-end: -51px;
            }
          }
          @if $text-direction == 'rtl' {
            .slick-prev {
              #{$rdirection}: 0;
              #{$ldirection}: auto;
              margin-inline-end: -46px;
              @include breakpoint($bp--medium-up) {
                margin-inline-end: -52px;
              }
            }
            .slick-next {
              margin-inline-start: -44px;
              @include breakpoint($bp--medium-up) {
                margin-inline-start: -52px;
              }
            }
            .slick-prev,
            .slick-next {
              margin-block-start: -18px;
              @include breakpoint($bp--medium-up) {
                margin-block-start: -7px;
              }
            }
          }
          .mobile-items-per-row-2 & {
            .slick-prev {
              margin-inline-start: -30px;
              #{$ldirection}: 0;
              @include breakpoint($bp--medium-up) {
                margin-inline-start: -51px;
              }
            }
            .slick-next {
              margin-inline-end: -27px;
              @include breakpoint($bp--medium-up) {
                margin-inline-end: -51px;
              }
            }
            .slick-prev,
            .slick-next {
              height: 30px;
              width: 30px;
              margin-block-start: -9px;
              @include breakpoint($bp--medium-up) {
                height: 54px;
                width: 34px;
                margin-block-start: -11px;
              }
              .icon {
                margin-block-start: -5px;
                @include breakpoint($bp--medium-up) {
                  margin-block-start: 28px;
                }
              }
            }
            @if $text-direction == 'rtl' {
              .slick-prev,
              .slick-next {
                margin-block-start: -11px;
                @include breakpoint($bp--medium-up) {
                  margin-block-start: -7px;
                }
              }
              .slick-prev {
                #{$rdirection}: 0;
                #{$ldirection}: auto;
                margin-inline-end: -31px;
                @include breakpoint($bp--medium-up) {
                  margin-inline-end: -52px;
                }
              }
              .slick-next {
                margin-inline-start: -34px;
                @include breakpoint($bp--medium-up) {
                  margin-inline-start: -52px;
                }
              }
            }
          }
        }
      }
    }
    &__shade {
      width: 100%;
      padding: 0 15px;
      @include breakpoint($bp--medium-up) {
        width: 60%;
        padding: 0;
      }
    }
  }
}
//MPP star
.grid--mpp__item {
  .product__rating-stars {
    background-image: none;
    .opaque {
      opacity: 0.5;
    }
  }
}
